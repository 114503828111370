// convention:
// [prefix.]keyName
//
// use [a.] prefix when key is used throughout whole application for functional items like action buttons (yes, no close), statuses (loading) etc
// use [module/componentName] when key is used for app control purposes only in given component ex. 'upload leaflet'
// use [b] prefix if it is a business/domain knowledge term, doesn't matter if in single component or in many places

const en = {
  'a.2many': 'Too many results, please refine your search',
  'a.actions': 'Actions',
  'a.active': 'Active',
  'a.add': 'Add',
  'a.all': 'All',
  'a.any': 'Any',
  'a.cancel': 'Cancel',
  'a.clear': 'Clear',
  'a.click2expand': 'Click to expand',
  'a.close': 'Close',
  'a.confirm': 'Confirm',
  'a.copy': 'Copy',
  'a.details': 'Details',
  'a.edit': 'Edit',
  'a.editSelected': 'Edit selected',
  'a.erase': 'Erase',
  'a.error': 'Error',
  'a.error2': 'An error occurred while processing your request',
  'a.export': 'Export',
  'a.exportExcel': 'Export to Excel',
  'a.framing': 'Framing',
  'a.inactive': 'Inactive',
  'a.invalidAttr': 'Invalid attribute value',
  'a.loading': 'Loading',
  'a.new': 'New',
  'a.next': 'Next',
  'a.no': 'No',
  'a.of': 'of',
  'a.override': 'Override',
  'a.overwrite': 'Overwrite',
  'a.paste': 'Paste',
  'a.pasteSelected': 'Paste into selection',
  'a.prev': 'Previous',
  'a.progress': 'Progress',
  'a.reason': 'Reason',
  'a.remove': 'Remove',
  'a.removeSelected': 'Remove selected',
  'a.replace': 'Replace',
  'a.replaceW': 'Replace with',
  'a.right4context': 'Right-click for context menu',
  'a.save': 'Save',
  'a.saveCopy': 'Save Copy',
  'a.savePrompt': 'Are you sure you want to save the changes?',
  'a.search': 'Search',
  'a.searchLimit': 'Showing top 100 results',
  'a.select': 'Select',
  'a.selectAll': 'Select All',
  'a.settings': 'Settings',
  'a.show': 'Show',
  'a.submit': 'Submit',
  'a.summary': 'Summary',
  'a.tooltipClck2Remove': 'Click to remove',
  'a.tooltipClick2Activate': 'Click to activate',
  'a.tooltipClick2Deactivate': 'Click to deactivate',
  'a.type': 'Type',
  'a.value': 'Value',
  'a.valueFrom': 'From',
  'a.valueTo': 'To',
  'a.yes': 'Yes',
  'advertisementsOverview.allAdvertisements': 'All advertisements',
  'advertisementsOverview.manuallyCreatedAdvertisements': 'Manually created advertisments',
  'advertisementsOverview.connectedFrames': 'Other connected frames',
  'advertisementsOverview.disconnectFrameMsg': 'Do you want to release this frame from all connected frames?',
  'advertisementsOverview.deleteFrame': 'Delete frame',
  'advertisementsOverview.deleteFrameConfirm': 'Do you really want to remove selected frame and all related promotions?',
  'advertisementsOverview.deleteFrameErr': 'Unable to delete frame',
  'advertisementsOverview.disconnectPage': 'Disconnect Page',
  'advertisementsOverview.disconnectPageMsg': 'Do you really want to disconnect this page from all duplicates?',
  'advertisementsOverview.disconnectPageSucc': 'Page successfully disconnected.',
  'advertisementsOverview.disconnectPageErr': 'Failed disconnecting page.',
  'advertisementsOverview.fetchPromoErr': 'Error fetching promotions',
  'advertisementsOverview.getIfDuplicatesErr': 'Error checking for duplicates.',
  'advertisementsOverview.overview': 'Advertisements overview',
  'advertisementsOverview.updateErr': 'Error updating promotions',
  'advertisementsOverview.updateSucc': 'Promotions updated',
  'advertisingArea.cantSetAsRep': 'This branch is already set as representative for different area',
  'advertisingArea.create': 'Create new advertising area',
  'advertisingArea.err': 'Error fetching advertising area',
  'advertisingArea.invalidRepresentative': 'Selected representative branch is already in use.',
  'advertisingArea.name': 'Advertising area name',
  'advertisingArea.saveErr': 'Error saving advertising area',
  'advertisingArea.setAsRep': 'Set as representative branch',
  'advertisingArea.succ': 'Advertising area saved',
  'advertisingAreaList.err': 'Error fetching advertising areas',
  'agGrid.reset': 'Reset columns',
  'attributes.activeAssignment': 'Active assignment',
  'attributes.err': 'Error fetching attributes',
  'attributes.label': 'Attributes',
  'attributes.literalRemoved': 'Literal removed',
  'attributes.name': 'Attribute name',
  'attributes.new': 'Create new attribute',
  'attributes.savePrompt': 'Any changes made regarding activation/deactivation of assigned attributes and values will be applied to all related products.',
  'attributes.saveErr': 'Error saving attribute',
  'attributes.selectedUnits': 'Selected units',
  'attributes.step1': 'Set attribute name',
  'attributes.step2': 'Choose attribute type',
  'attributes.step3': 'Setup content',
  'attributes.succ': 'Attribute saved',
  'attributes.type': 'Attribute type',
  'attributes.typeBrickDependentSet': 'Brick-dependent set of literals',
  'attributes.typeNumUnit': 'Number with unit',
  'attributes.typeRangeUnit': 'Range with unit',
  'attributes.typeSet': 'Set of literals',
  'attributes.typeTxt': 'Freetext',
  'b.absoluteD': 'Absolute',
  'b.absoluteDiscount': 'Absolute discount',
  'b.addAlternativeDescription': 'Add alternative description',
  'b.addMainDescription': 'Add main description',
  'b.address': 'Address',
  'b.advertisementProposals': 'Advertisement/Proposals',
  'b.advertisingArea': 'Advertising Areas',
  'b.advertisingAreas': 'Advertising areas',
  'b.alternativeDescription': 'Alternative descriptions',
  'b.banner': 'Banner',
  'b.banners': 'Banners',
  'b.bpco': 'BPCO',
  'b.bpcoDescription': 'BPCO description',
  'b.bpcoAdded': 'BPCO added',
  'b.branches': 'Branches',
  'b.brand': 'Brand',
  'b.brands': 'Brands',
  'b.capacity': 'Capacity',
  'b.capacityUnit': 'Capacity unit',
  'b.categories': 'Product categories',
  'b.category': 'Category',
  'b.categoryAttributes': 'Category attributes',
  'b.categoryBrick': 'Brick',
  'b.categoryCharacteristics': 'Category characteristics',
  'b.categoryClass': 'Class',
  'b.categoryFamily': 'Family',
  'b.categorySegment': 'Segment',
  'b.characteristics': 'Characteristics',
  'b.city': 'City',
  'b.contentOfTradingUnit': 'Content of trading unit',
  'b.contentOfTradingUnitUnit': 'Content of trading unit unit',
  'b.country': 'Country',
  'b.countryThemedWeek': 'Country-themed weeks',
  'b.dataLanguage': 'Data language',
  'b.deprecated': 'Deprecated',
  'b.deprecatedCharacteristics': 'Deprecated characteristics',
  'b.descr': 'Description',
  'b.descriptionLangErr': 'Only one main description per language can exist',
  'b.descriptionReplaced': 'Description replaced',
  'b.descriptionSaveErr': 'Error saving description',
  'b.descriptionSaveSucc': 'Description saved',
  'b.enterHeaderData': 'Enter header data',
  'b.fileName': 'File name',
  'b.franchiseOwner': 'Franchise Owner',
  'b.franchiseOwners': 'Franchise owners',
  'b.general': 'General Information',
  'b.gtin': 'GTIN',
  'b.houseNumber': 'House Number',
  'b.industry': 'Industry',
  'b.isMarkantRetailPartner': 'Markant retail partner',
  'b.issuesType': 'Issues type',
  'b.keyAccount': 'Key Account',
  'b.latitude': 'Latitude',
  'b.leafletValidity': 'Leaflet validity',
  'b.location': 'Location',
  'b.longitude': 'Longitude',
  'b.mainDescription': 'Main descriptions',
  'b.market': 'Market',
  'b.media': 'Media',
  'b.mediumType': 'Medium type',
  'b.mpu': 'Minimum purchase',
  'b.MPU': 'MPU',
  'b.name': 'Name',
  'b.nielsenRegion': 'Nielsen Region',
  'b.pageValidity': 'Page validity',
  'b.postCode': 'Post Code',
  'b.price': 'Price',
  'b.priceGross': 'Gross price',
  'b.priceNet': 'Net price',
  'b.product': 'Product',
  'b.productAdded': 'Product added',
  'b.productCopy': 'Copy of Product',
  'b.productDescription': 'Product description',
  'b.productLine': 'Product Line',
  'b.promotionCountryWeek': 'Country-themed weeks',
  'b.promotionQS': 'Quality seal',
  'b.promotionSeasonTheme': 'Season / Theme',
  'b.promotionType': 'Promotion type',
  'b.promotionalP': 'Promotional',
  'b.promotionalPrice': 'Promotional price',
  'b.promptLockExpire': 'Your lock has expired due to inactivity. You will be redirected to the previous page shortly.',
  'b.region': 'Region',
  'b.regularP': 'Regular',
  'b.regularPrice': 'Regular price',
  'b.relativeD': 'Relative',
  'b.relativeDiscount': 'Relative discount',
  'b.reportedBy': 'Reported by',
  'b.representativeBranch': 'Representative branch',
  'b.retailer': 'Retailer',
  'b.retailerHQ': 'Retailer headquarter',
  'b.retailers': 'Retailers',
  'b.street': 'Street',
  'b.supplier': 'Supplier',
  'b.suppliers': 'Suppliers',
  'b.taxRate': 'Tax rate',
  'b.typeOfBranch': 'Type of Branch',
  'b.typeOfBranchFranchise': 'Franchise',
  'b.typeOfBranchStd': 'Standard',
  'b.typeOfPackage': 'Type of package',
  'b.userInterfaceLanguage': 'Interface language',
  'b.unit': 'Unit',
  'b.variety': 'Variety',
  'b.validFrom': 'Valid from',
  'b.validTo': 'Valid to',
  'banner.duplicate': 'This banner already exists',
  'banner.err': 'Error fetching banners',
  'banner.publishWithValidity': 'Publish with validity',
  'banner.saveErr': 'Error saving banner',
  'banner.succ': 'Banner saved',
  'banner.tooltipPublishWithValidity': 'All media for a banner with this flag is only published in MPM analysis system as soon as the validity of a medium starts. It does not matter when the medium was entered.',
  'bpco.createCopy': 'Create copy of BPCO',
  'bpco.descriptionDuplicate': 'Same BPCO description already exists.',
  'bpco.duplicate': 'Same brand product category object already exist',
  'bpco.err': 'Error fetching brand product category object',
  'bpco.err2': 'Error fetching BPCO description',
  'bpco.errorFetchingCreated': 'Error fetching created description. Please use search to find it.',
  'bpco.saveErr': 'Error saving brand product category object',
  'bpco.succ': 'Brand product category object saved',
  'branchDetails.cantDeactivate': 'Unable to deactivate. Branch is representative for advertisement area.',
  'branchDetails.err': 'Error fetching branch details',
  'branchDetails.isRep': 'Can not deactivate - branch is representative for ',
  'branchDetails.saveErr': 'Error saving branch details',
  'branchDetails.succ': 'Branch details saved',
  'branchesOf': 'Branches of ',
  'branchesSelector.avail': 'Available branches',
  'branchesSelector.selected': 'Selected branches',
  'brand.already': 'Brand already used in this structured brand',
  'brand.create': 'Create new brand',
  'brand.createStructured': 'Create new structured brand',
  'brand.duplicate': 'Brand already exist',
  'brand.duplicateConfirmation1': 'Duplicates or similar brands were found for this brand.',
  'brand.duplicateConfirmation2': 'Do you still want to create a new brand?',
  'brand.duplicateFound': 'Duplicated / similar brand(s)',
  'brand.edit': 'Edit brand',
  'brand.editStructured': 'Edit structured brand',
  'brand.err': 'Error fetching brand',
  'brand.errs': 'Error fetching brands',
  'brand.main': 'Main brand',
  'brand.name': 'Brand name',
  'brand.saveErr': 'Error saving brand',
  'brand.secondary': 'Secondary brands',
  'brand.selectSec': 'Select secondary brand',
  'brand.succ': 'Brand saved',
  'brand.struct': 'Structured brand',
  'category.alreadySelected': 'This attribute is already selected',
  'category.alternative': 'Alternative category',
  'category.attrUpdateErr': 'Error updating category attributes',
  'category.bName': 'Brick name',
  'category.cName': 'Class name',
  'category.confirmation': 'The depending items will be assigned to the declared alternative.',
  'category.createErr': 'Error creating Category',
  'category.createSucc': 'Category created',
  'category.err': 'Error fetching category',
  'category.expandAll': 'Expand all',
  'category.expandRow': 'Expand row',
  'category.fName': 'Family name',
  'category.newBrick': 'Create Brick',
  'category.newClass': 'Create Class',
  'category.newFamily': 'Create Family',
  'category.newSegment': 'Create Segment',
  'category.parent': 'Parent category',
  'category.requireLiterals': 'Attribute require literals assignment',
  'category.setupBrickDependentAttr': 'Set literals for {attributeName}',
  'category.sName': 'Segment name',
  'category.updateErr': 'Error saving category',
  'category.updateSucc': 'Category updated',
  'dictionary.dict': 'Dictionary',
  'dictionary.err': 'Error fetching dictionary',
  'dictionary.new': 'Add new element to this dictionary',
  'dictionary.saveErr': 'Error updating dictionary',
  'dictionary.succ': 'Dictionary updated',
  'dictionaryElement.alt': 'Alternative value',
  'dictionaryElement.alternative': 'Alternative value',
  'dictionaryElement.err': 'Error fetching dictionary element',
  'dictionaryElement.info': 'Double click to edit',
  'dictionaryElement.info2': 'English and at least one other translation required',
  'dictionaryElement.lang': 'Language',
  'dictionaryElement.trans': 'Translation',
  'fileUpload.chooseFiles': 'Choose files',
  'fileUpload.formatWarning': 'Files {files} have invalid format',
  'fileUpload.or': 'or',
  'fileUpload.preventIdenticalRecognition': 'Prevent identical page recognition',
  'fileUpload.selectedFile': 'Selected file',
  'fileUpload.uploadFilePlaceholder': 'Drag and drop file',
  'filters': 'Filters',
  'filters.clear': 'Clear Filters',
  'fixReportedFrames.cantSubmitEmpty': 'There are frames on this page. It  can\'t be marked as empty',
  'frame.clear': 'Clear all frames',
  'frame.empty': 'Empty page',
  'frame.emptyConfirm': 'No frames created. Do you want to mark this page as empty?',
  'frame.err': 'Error saving frames',
  'frame.fitToWindow': 'Fit to window',
  'frame.reviseFramesPrevPage': 'Revise frames on previous page',
  'frame.noPages': 'No pages to frame',
  'frame.skip': 'Skip page',
  'frame.skipped': 'Page skipped',
  'frame.submit': 'Submit frames',
  'frame.succ': 'Frames saved',
  'frame.validFrom': 'Frame valid from',
  'frame.validTo': 'Frame valid to',
  'frame.validity': 'Frame validity',
  'frameDescription.addFrameValidity': 'Add frame validity',
  'frameDescription.apply2selected': 'Apply to selected promotions',
  'frameDescription.confirmSelectedRemoval': 'Remove {count} promotions?',
  'frameDescription.eraseAction': 'Erase values from selected promotions',
  'frameDescription.err': 'Error fetching frame data',
  'frameDescription.errCantReuse': 'Unable to use your product. Please add it manually using search functionality.',
  'frameDescription.errImg': 'Error fetching frame image',
  'frameDescription.errNoFrame': 'No frame available',
  'frameDescription.fetchProdSug': 'Looking for related products...',
  'frameDescription.fetchProdSugErr': 'Error searching related products',
  'frameDescription.fetchProdSugNothing': 'Matching products not found',
  'frameDescription.fetchProdSugTitle': 'Product suggestions',
  'frameDescription.fetchPromoSugErr': 'Error searching related promotions',
  'frameDescription.fetchPromoSugNothing': 'Matching promotions not found',
  'frameDescription.fetchPromoSugTitle': 'Suggested promotions',
  'frameDescription.report': 'Report frame',
  'frameDescription.reportAdvertisement': 'Report advertisement',
  'frameDescription.reported': 'Frame reported',
  'frameDescription.reportReason1': 'Framing issue',
  'frameDescription.reportReason2': 'Unable to describe',
  'frameDescription.reportedAdvertisement': 'Advertisement reported',
  'frameDescription.saveErr': 'Error saving advertisement',
  'frameDescription.skip': 'Skip frame',
  'frameDescription.skipped': 'Frame skipped',
  'frameDescription.succ': 'Advertisement created successfully',
  'franchiseOwner.create': 'Create new franchise owner',
  'franchiseOwner.err': 'Error fetching franchise owner',
  'franchiseOwner.name': 'Franchise owner name',
  'franchiseOwner.saveErr': 'Error saving franchise owner',
  'franchiseOwner.succ': 'Franchise owner saved',
  'franchiseOwnersList.err': 'Error fetching franchise owners',
  'gtin.duplicatedGtins': 'Duplicated GTINS',
  'gtin.productLocked': 'This product is locked by another user',
  'gtin.errorUploadingFile': 'Error uploading file',
  'gtin.errorUploadingFileExtension': 'Error: wrong file extension. Please select .csv file',
  'gtin.importGtins': 'Import GTIN',
  'gtin.incorrectGtins': 'Incorrect GTINS',
  'gtin.incorrectProducts': 'Incorrect products',
  'gtin.numberOfAdjustedProducts': 'Number of adjusted products',
  'gtin.productsWithoutGtins': 'Products without GTINS',
  'gtin.recordInFile': 'Records in file',
  'headerData.addPageValidity': 'Add page validity',
  'headerData.addPageCountryThemedWeeks': 'Add page country-themed weeks',
  'headerData.addPageSeasonTheme': 'Add page Season/Theme',
  'headerData.addPagePromotionType': 'Add page Promotion type',
  'headerData.countryThemedWeeksPerPage': 'Country-themed weeks per page',
  'headerData.copied': 'Header data stored in clipboard',
  'headerData.copiedErr': 'No data in clipboard',
  'headerData.duplicateConfirm': 'Do you want to save this Header Data?',
  'headerData.duplicateLeaflet': 'Leaflet with same Header Data already exists.',
  'headerData.duplicateLeafletCountryThemedWeeeks': 'Page country-themed weeks error - same page used multiple times: ',
  'headerData.duplicateLeafletPromotionTypePages': 'Page promotion type error - same page used multiple times: ',
  'headerData.duplicateLeafletThemeSeasonsPages': 'Page season theme error - same page used multiple times: ',
  'headerData.duplicateLeafletValidityPages': 'Page validity error - same page used multiple times: ',
  'headerData.err': 'Error fetching leaflet',
  'headerData.errLeafletValidityPages': 'Page validity error - missing date',
  'headerData.errLocked': 'This leaflet is locked by another user',
  'headerData.invalidPriceMode': 'Invalid price mode value',
  'headerData.leafletLanguage': 'Leaflet language',
  'headerData.leafletValidF': 'Leaflet valid from',
  'headerData.leafletValidT': 'Leaflet valid to',
  'headerData.pages': 'Pages',
  'headerData.pgValidF': 'Page valid from',
  'headerData.pgValidT': 'Page valid to',
  'headerData.promotionTypesPerPage': 'Promotion type per page',
  'headerData.saveErr': 'Error saving leaflet',
  'headerData.seasonThemePerPage': 'Season/Theme per page',
  'headerData.selectBranches': 'Select branches',
  'headerData.selectByAdvArea': 'Select by advertising area',
  'headerData.succ': 'Leaflet details saved',
  'headerData.useAdvArea': 'Use branches of {areaName} area',
  'headerData.useComboAdvAreas': 'Use branches of selected areas',
  'headerData.validityPerPage': 'Validities per page',
  'hierarchy.banners': 'Banners for {retailerName}',
  'hierarchy.confirmation1': ' will be deactivated.',
  'hierarchy.confirmation2': 'Related items will also be deactivated: ',
  'hierarchy.createBanner': 'Create Banner',
  'hierarchy.createKeyAccount': 'Create Key Account',
  'hierarchy.createRetailer': 'Create Retailer',
  'hierarchy.emptyList': 'No items found',
  'hierarchy.keyAccounts': 'Key Accounts',
  'hierarchy.retailers': 'Retailers for {keyAccountName}',
  'industries.err': 'Error fetching industries',
  'industrySelector.err': 'Error fetching industries',
  'keyAccount.duplicate': 'This key account already exists',
  'keyAccount.saveErr': 'Error saving key account',
  'keyAccount.succ': 'Key account saved',
  'language.bg': 'Bulgarian',
  'language.cs': 'Czech',
  'language.de': 'German',
  'language.de_AT': 'Austrian German',
  'language.de_CH': 'Swiss German',
  'language.en': 'English',
  'language.fr': 'French',
  'language.hr': 'Croatian',
  'language.hu': 'Hungarian',
  'language.it': 'Italian',
  'language.nl': 'Dutch',
  'language.pl': 'Polish',
  'language.ro': 'Romanian',
  'language.sk': 'Slovakian',
  'language.sl': 'Slovenian',
  'leafletBrowser.goToFrame': 'Go to frame',
  'leafletBrowser.report': 'Report page',
  'leafletBrowser.reported': 'Page reported',
  'leafletPage.error': 'Error loading page image',
  'leafletStatus.described': 'Described',
  'leafletStatus.desribingInProgress': 'Describing in progress',
  'leafletStatus.done': 'Finished',
  'leafletStatus.framed': 'Framed',
  'leafletStatus.framesDescribed': 'Frames described',
  'leafletStatus.paged': 'Paged',
  'leafletStatus.uploaded': 'Uploaded',
  'leafletUpload.clearAll': 'Clear all',
  'leafletUpload.clearUploaded': 'Clear uploaded',
  'leafletUpload.fileSize': 'File size',
  'leafletUpload.pressAdvertisement': 'Press Advertisement',
  'leafletUpload.statusCancelled': 'Upload cancelled',
  'leafletUpload.statusLoaded': 'Uploaded',
  'leafletUpload.statusLoading': 'Upload pending',
  'leafletUpload.statusNew': 'Queued',
  'leafletUpload.uploadFailed': 'An error occurred during file upload',
  'leafletUpload.uploadSuccess': 'Files uploaded successfully',
  'leafletUploadNavButton.leafletUploadCTA': 'Upload leaflet',
  'literals.activeLiterals': 'Active literals',
  'literals.duplicate': 'This value already exist on the list',
  'literals.err': 'Error fetching literal data',
  'literals.errCreate': 'Error creating literal',
  'literals.errUpdate': 'Error updating literal',
  'literals.inactiveLiterals': 'Inactive literals',
  'literals.label': 'Literals',
  'literals.new': 'Create new literal',
  'literals.succCreate': 'Literal created',
  'literals.succUpdate': 'Literal updated',
  'log.copyAll': 'Copy all',
  'log.copyToday': 'Copy today events',
  'log.date': 'Event date',
  'log.details': 'Event details',
  'log.header': 'Incidents log',
  'log.removeAll': 'Remove all entries',
  'masterData.BPCO': 'Brand product category object',
  'masterData.attributeError': 'Error loading attribute',
  'masterData.basicMasterData': 'Basic Data',
  'masterData.dictionaries': 'Dictionaries',
  'masterData.productLines': 'Product Lines',
  'masterData.products': 'Products',
  'masterData.retailerHierarchy': 'Key Account/Retailer/Banner',
  'media.chooseRadioSpotFile': 'Select radiospot file to upload',
  'media.copyFileName': 'Copy file name',
  'media.delete': 'Delete spot',
  'media.deleteErr': 'Error occurred while deleting spot',
  'media.deleteFile': 'Delete file',
  'media.deletePrompt': 'Do you really want to delete this spot?',
  'media.deleteSucc': 'Spot successfully deleted',
  'media.describeMedia': 'Describe media',
  'media.errNoMedia': 'No media available',
  'media.radioAudiofileFetchErr': 'Error fetching audiofile',
  'media.radioFetchErr': 'Error fetching radio spot data',
  'media.radioSpotDescribe': 'Describe radio spot',
  'media.radioSpotFilterStatus': 'Show radio spot with status: ',
  'media.radioSpotLanguage': 'Radio spot language',
  'media.radioSpotPlayAudio': 'Play radiospot audiofile',
  'media.radioSpotSaveErr': 'Error saving radio spot',
  'media.radioSpotSucc': 'Radio spot saved',
  'media.radioSpotVerify': 'Verify radio spot',
  'media.spotLocked': 'This media spot is locked by another user',
  'media.statusDone': 'Finished',
  'media.statusPending': 'Pending for proposals',
  'media.statusRegistered': 'Verification needed',
  'media.statusVerified': 'Ready to describe',
  'media.transcriptProposals': 'Transcript proposals',
  'media.transcription': 'Transcription',
  'nav.goTo': 'Go to...',
  'nav.advertisement': 'Advertisement',
  'nav.frame': 'Frame',
  'nav.framingCTA': 'Start framing',
  'nav.leaflet': 'Leaflet',
  'nav.masterData': 'Master Data',
  'nav.mediumRadio': 'Radio spot overview',
  'nav.myAdvertisements': 'My advertisements',
  'nav.overviewAdvertisements': 'Advertisements overview \n(print media)',
  'nav.overviewAdvertisementsPromo': 'Advertisements overview (promotion)',
  'nav.overviewFrames': 'Frames overview',
  'nav.overviewQuality': 'Statistics',
  'nav.overviewAdvertisementsSpot': 'Advertisements overview (spot/media)',
  'nav.quality': 'Quality',
  'nav.qualityBrowse': 'Reported issues',
  'nav.showLeaflet': 'Show Leaflet',
  'page.locked': 'This page is locked by another user',
  'printMediaOverview.createdBy': 'Created by',
  'printMediaOverview.creationDate': 'Created',
  'printMediaOverview.currentUsers': 'Current users',
  'printMediaOverview.deleteConfirmation': 'Do you really want to delete this Leaflet?',
  'printMediaOverview.deleteInstruction': 'To confirm please write "DELETE".',
  'printMediaOverview.delete': 'Delete leaflet',
  'printMediaOverview.deleteSucc': 'Leaflet successfully deleted',
  'printMediaOverview.deleteErr': 'Error occurred while deleting leaflet',
  'printMediaOverview.describeFrame': 'Describe frame',
  'printMediaOverview.describedFrames': 'Described frames',
  'printMediaOverview.editFrames': 'Edit frames',
  'printMediaOverview.editHeaderData': 'Edit header data',
  'printMediaOverview.filter': 'Filter leaflets by Banner:',
  'printMediaOverview.filterBanner': 'Show leaflets for banner: ',
  'printMediaOverview.filterLanguage': 'Show leaflets with language: ',
  'printMediaOverview.filterStatus': 'Show leaflets in status: ',
  'printMediaOverview.headerDataCTA': 'Enter header data',
  'printMediaOverview.leafletDescribed': 'Ready to frame',
  'printMediaOverview.leafletFramed': 'Ready to describe',
  'printMediaOverview.leafletPaged': 'Require header data',
  'printMediaOverview.leafletReady': 'Finished',
  'printMediaOverview.leafletType': 'Leaflet type',
  'printMediaOverview.leafletUploaded': 'Paging in progress',
  'printMediaOverview.modifiedBy': 'Modified by',
  'printMediaOverview.noProgressData': 'No progress data available',
  'printMediaOverview.numberOfPages': 'Number of pages',
  'printMediaOverview.overview': 'Print media overview',
  'printMediaOverview.reportedFrames': 'Reported frames',
  'printMediaOverview.reportedPages': 'Reported pages',
  'printMediaOverview.selectLanguage': 'Leaflet language',
  'printMediaOverview.status': 'Status',
  'printMediaOverview.totalFrames': 'Total frames',
  'printMediaOverview.validityDates': 'Validity dates',
  'product.addGtin': 'Add GTIN',
  'product.bulk': 'Bulk product update',
  'product.bulkDescription': 'Bulk product description change',
  'product.bulkDescriptionHelp': 'Displayed attributes comes from newly chosen brick. At least one of the selected products comes from a brick not associated with those attributes. Complete the missing values. Remaining attributes which are in line with the current brick will be automatically transferred.',
  'product.bulkDuplicates': 'This bulk update will create duplicated products ({count}). They will be deactivated and replaced. This operation is irreversible.',
  'product.bulkSucc': 'Products updated successfully',
  'product.bulkSucc2': 'Updating search engine, this might take a while...',
  'product.bulkSupplier': 'Bulk brand/supplier assignment',
  'product.bulkSupplierConfirm': 'You are going to set {sName} as a supplier for {count} products.',
  'product.bulkUpdate': 'Update {count} products',
  'product.bulkUpdateDescription': 'Update {count} products with following description',
  'product.chooseDescription': 'Choose description',
  'product.confirmReplace': 'Confirm product replacement. This operation is irreversible.',
  'product.confirmReplaceDescription': 'Confirm description replacement. This operation is irreversible.',
  'product.confirmUpdate': '{count} products will be updated.',
  'product.copyAllGtins': 'Copy all GTINs',
  'product.copyThisGtin': 'Copy this GTIN',
  'product.createCopy': 'Create copy of product',
  'product.duplicate': 'Same product already exist',
  'product.duplicateConfirmPrompt': 'Same product already exist. Do you want to combine them?',
  'product.err': 'Error fetching product',
  'product.errImg': 'Error fetching product images.',
  'product.errAdding': 'Error adding product',
  'product.fixReported': 'Mark product as fixed',
  'product.gtinAdded': 'GTIN added',
  'product.gtinErr': 'Invalid GTIN',
  'product.gtinExists': 'This GTIN is already used',
  'product.gtinFetchErr': 'Error fetching GTIN details',
  'product.gtinRemoved': 'GTIN removed',
  'product.gtinVerified': 'Verified',
  'product.hasIssues': 'This product has unresolved issues',
  'product.hideImages': 'Hide images',
  'product.isTemplate': 'Product template',
  'product.issuesResolved': 'Product issues has been resolved',
  'product.listErr': 'Error fetching products list',
  'product.noImgAvailable': 'No images available.',
  'product.relatedPromotions': 'Related promotions',
  'product.reported': 'Product reported',
  'product.saveErr': 'Error saving product',
  'product.showAdvertisement': 'Show advertisement',
  'product.showImages': 'Show images',
  'product.showTemplates': 'Show templates',
  'product.succ': 'Product saved',
  'product.succReplaced': 'Product has been replaced',
  'product.toReplace': 'Product to replace',
  'productCategories.brick': 'Product category: brick',
  'productDescription.createNew': 'Create new product description',
  'productDescription.createCopy': 'Create copy of product description',
  'productDescription.err': 'Error fetching product description',
  'productDescription.listErr': 'Error fetching product descriptions list',
  'productDescription.duplicate': 'Same product description already exists.',
  'productDescription.replaceErr': 'Error during product description replacement',
  'productLine.duplicate': 'Same Product Line already exist',
  'productLine.err': 'Error fetching Product Line',
  'productLine.listErr': 'Error fetching Product Line list',
  'productLine.name': 'Product Line name',
  'productLine.productsOf': 'Products of',
  'productLine.saveErr': 'Error saving Product Line',
  'productLine.succ': 'Product Line saved',
  'productSearch.alreadySelected': 'Product already selected',
  'productSearch.bundling': 'Bundling',
  'productSearch.type': 'Type',
  'productSearch.giveAway': 'Give-away',
  'productSearch.giveAwayBundling': 'Give-away / Bundling',
  'productSearch.createBPCO': 'Create new BPCO',
  'productSearch.clonePromotion': 'Clone promotion',
  'productSearch.clonePromotionSelected': 'Clone selected promotions',
  'productSearch.createProduct': 'Create new product',
  'productSearch.results': 'Found products',
  'productSearch.toggleSearch': 'Search BPCOs',
  'productsSelector.avail': 'Available products',
  'productsSelector.selected': 'Selected products',
  'promotion.copied': 'Data stored in clipboard',
  'promotion.copiedErr': 'No data in clipboard',
  'promotion.derivedPropsExplanation': 'These leaflet values will be applied to this product',
  'promotion.more': 'And {count} more',
  'promotion.overridePropsExplanation': 'Set new value for this product to override leaflet values',
  'promotion.searchByProduct': 'Search is now limited to one selected product',
  'promotion.validFrom': 'Promotion valid from',
  'promotion.validTo': 'Promotion valid to',
  'promotion.validity': 'Promotion validity',
  'reportedFramesBrowser.fixAdvertisementBtn': 'Fix advertisement',
  'reportedFramesBrowser.fixBtn': 'Fix frames',
  'reportedFramesSummary.pageNo': 'Page',
  'reportProduct.label': 'Report product',
  'retailer.duplicate': 'This retailer already exists',
  'retailer.saveErr': 'Error saving retailer',
  'retailer.succ': 'Retailer saved',
  'retailerHQ.create': 'Create new retailer headquarter',
  'retailerHQ.err': 'Error fetching retailer headquarter',
  'retailerHQ.name': 'Retailer headquarter name',
  'retailerHQ.names': 'Retailer headquarters',
  'retailerHQ.saveErr': 'Error saving retailer headquarter',
  'retailerHQ.succ': 'Retailer headquarter saved',
  'retailerHQList.err': 'Error fetching retailer headquarters',
  'searchProductLine': 'Search for Product Line',
  'statistics.generalStatistics': 'General statistics',
  'statistics.manuallyCreatedPromotions': 'Manually created promotions',
  'statistics.manuallyCreatedPromotionsInfoBtn': 'You can export data by clicking "Export to excel" button',
  'statistics.media': 'Number of advertising media',
  'statistics.mediaErr': 'Error fetching media data',
  'statistics.prod': 'Number of products',
  'statistics.promoErr': 'Error fetching promotions data',
  'statistics.wkYr': 'Week\n\\\nYear',
  'statistics.selectCountries': 'Choose countries for report',
  'supplier.created': 'Supplier created',
  'supplier.error': 'Error fetching supplier',
  'supplier.errorCreate': 'Error creating supplier',
  'supplier.errorDuplicate': 'Supplier already exists',
  'supplier.errors': 'Error fetching suppliers',
  'supplier.errorUpdate': 'Error updating supplier',
  'supplier.search': 'Search for supplier',
  'supplier.select': 'Select supplier',
  'supplier.selectAndBrand': 'Select brand/supplier',
  'supplier.updated': 'Supplier updated',
  'timeRange.currentWeek': 'Current week',
  'timeRange.today': 'Today',
  'timeRange.yesterday': 'Yesterday',
  'typeOfBranchSelector.err': 'Error fetching type of branches',
  'v.notANumber': 'Value is not a number',
  'v.invalidChars': 'Only digits and -+*()/,. allowed',
  'v.invalidFormulaProduct': 'Formula result is invalid',
  'v.outOfRange': 'Value is out of range',
  'v.paramsRequired': 'Price or Discount required',
  'v.promoLessThanRegular': 'Promotion price must be less than regular price',
  'v.requiredIsEmpty': 'Required',
  'v.zeroNegative': 'Value is zero or negative',
  bg: 'Български',
  cs: 'Český',
  de: 'Deutsch',
  'de_AT': 'Deutsch (AT)',
  'de_CH': 'Deutsch (CH)',
  en: 'English',
  fr: 'Français',
  hr: 'Hrvatski',
  hu: 'Magyar',
  nl: 'Nederlands',
  pl: 'Polski',
  ro: 'Română',
  sk: 'Slovenský',
  sl: 'Slovenščina',
  it: 'Italiano'
};

export default en;
