import {basic} from './_common.model';
import {commonAttributeDataModel} from './attributeComponent.model';
import {
    apiGetPromotionsForFrameResponsePromotionType,
    apiGetPromotionsForFrameResponsePromotionTypeProductType
} from './frame.model';
import {characteristicUI, shortCharacteristic} from './product.model';
import {validity} from './validity.model';

export enum PROMOTION_TYPES {
    PRODUCT = 'PRODUCT',
    BPCO = 'BPCO'
}

export enum PROPOSALS_ACTIVITY_TYPE {
    'PROPOSAL_ACCEPTED' = 'PROPOSAL_ACCEPTED',
    'PROPOSAL_REJECTED' = 'PROPOSAL_REJECTED'
}

export type framePromotionsPayload = {
    frameId: string,
    validFrom: string,
    validTo: string,
    promotions: promotionSave[]
}

export type framePromotionsPutPayload = framePromotionsPayload & {
    excludedDuplicate: boolean;
  };

export type fullSpotPromotions = {
    mediumId?: string,
    promotions: promotionUI[]
    validFrom: string,
    validTo: string
}

export type promotionSave = {
    absoluteDiscount: number,
    bpcoId?: string,
    giveAwayBundledProductIds?: string[],
    id?: string,
    mpu: number,
    productId?: string,
    promotionObjectType: PROMOTION_TYPES,
    promotionTypes: string[]
    promotionalPrice: number,
    qualitySeals: string[],
    regularPrice: number,
    relativeDiscount: number,
    themeSeasons: string[],
    countryThemedWeeks: string[],
    validityDates: validity[]
}

export type fullFramePromotions = {
    frameId?: string,
    validFrom: string,
    validTo: string,
    promotions: promotionUI[]
}

export type promotionUI = {
    absoluteDiscount: number,
    bpcoId?: string,
    brand: string,
    category: string,
    countryThemedWeeks: commonAttributeDataModel,
    currency: string,
    derivedPromotionTypes?: commonAttributeDataModel,
    derivedThemeSeasons?: commonAttributeDataModel,
    derivedThemedWeeks?: commonAttributeDataModel,
    description: string,
    giveAwayBundledProductIds?: string[],
    giveAwayBundledProducts?: apiGetPromotionsForFrameResponsePromotionTypeProductType[],
    id?: string,
    isTemplate?: boolean,
    mpu: number,
    productId?: string,
    promotionObjectType: PROMOTION_TYPES,
    promotionTypes: commonAttributeDataModel,
    promotionalPrice: number,
    qualitySeals: commonAttributeDataModel,
    regularPrice: number,
    relativeDiscount: number,
    themeSeasons: commonAttributeDataModel,
    // need to identify promotion item in UI model somehow.
    // cant use id as it exist only for saved promotions. cant rely on productId/bpcoId bc they exist only for products/bpcos
    UIId: string,
    characteristics: characteristicUI[],
    hasError: boolean,
    validityDates: validity[]
}

type promotionTableRowProductBE = {
    // active: boolean,
    // alternativeProductId: string,
    brand: string,
    category: string,
    description: string,
    gtin: string,
    id: string,
    isTemplate: boolean,
    productLineId: string,
    productLineName: string,
    characteristics: shortCharacteristic[]
}

type promotionTableRowBPCOBE = {
    // active: boolean,
    alternativeDescription: string,
    brand: string,
    category: string,
    description: string,
    id: string,
    characteristics: shortCharacteristic[]
}

export type promotionTableRowBE = {
    absoluteDiscount: number,
    bpco?: promotionTableRowBPCOBE,
    currency: string,
    frameId?: string,
    giveAwayBundledProducts,
    giveAwayBundledProductIds?: string[],
    id?: string,
    mpu: number,
    product?: promotionTableRowProductBE,
    promotionObjectType: PROMOTION_TYPES
    promotionTypes: string[],
    promotionalPrice: number,
    qualitySeals: string[],
    regularPrice: number,
    relativeDiscount: number,
    themeSeasons: string[],
    countryThemedWeeks: string[],
    frameValidFrom: string,
    frameValidTo: string,
    validityDates: validity[]
}

export type promotionMediaTableRowBE = Omit<promotionTableRowBE, 'frameValidFrom' | 'frameValidTo'>;

export type advertisementsMediaTableResponseModel = promotionMediaTableRowBE[];

export type advertisementsTableResponseModel = promotionTableRowBE[];

export type promotionTableRow = {
    absoluteDiscount: number,
    brand: string,
    category: string,
    currency: string,
    description: string,
    generalInformation: string[],
    giveAwayBundling: string[],
    id: string,
    isTemplate: boolean,
    mainAttributes: string,
    variety: string,
    contentOfTradingUnit:  string,
    capacity:  string,
    promotionalPrice: number,
    regularPrice: number,
    relativeDiscount: number,
    frameValidFrom: string,
    frameValidTo: string,
    validityDates: string[]
}

export type promotionMediaTableRow = Omit<promotionTableRow, 'frameValidFrom' | 'frameValidTo'>;

export type suggestedPromotionForFrame = Omit<apiGetPromotionsForFrameResponsePromotionType,
    'validity' | 'countryThemedWeeks' | 'promotionTypes' | 'qualitySeals' | 'themeSeasons'> & {
    countryThemedWeeks: basic[],
    promotionTypes: basic[],
    qualitySeals: basic[],
    themeSeasons: basic[],
};

export type promotionSuggestionsForFrame = {
    frameId: string,
    promotions: suggestedPromotionForFrame[], // simplified version of apiGetPromotionsForFrameResponsePromotionType[]
    validFrom: string,
    validTo: string
};

export type promotionSuggestionsResponseModel = promotionSuggestionsForFrame[];