/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import {API_ADVERTISEMENTS_MEDIA_OVERVIEW, API_STATUSES} from 'config/api/constants';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {advertisementsMediaTableResponseModel} from 'shared/models/promotion.model';
import AdvertisementsTable from 'modules/Advertisement/AdvertisementsOverview/AdvertisementsTable';

interface advertisementBrowserMediaSummaryProps {
    mediumId: string,
    lang: string
}

const AdvertisementBrowserMediaSummary = ({mediumId, lang}: advertisementBrowserMediaSummaryProps) => {

    const [promotionsAdvertisements, setPromotionsAdvertisements] = useState<advertisementsMediaTableResponseModel>([]);

    const advertisementsSpotsDataApiGet = useApi('get', {errMsg: 'media.radioFetchErr'});

    const getSpotData = (mediumId: string, lang: string) => {
        if (mediumId && lang) advertisementsSpotsDataApiGet.call(API_ADVERTISEMENTS_MEDIA_OVERVIEW(mediumId, lang));
    }

    useEffect(() => {
        getSpotData(mediumId, lang);
    }, [mediumId, lang]);

    useEffect(() => {
        if (advertisementsSpotsDataApiGet.status === API_STATUSES.IDLE) {
            if (advertisementsSpotsDataApiGet.data) {
                setPromotionsAdvertisements(advertisementsSpotsDataApiGet.data);
            }
        }
    }, [advertisementsSpotsDataApiGet.status]);

    return (
        <PaperX className="_fullHeight _fullTable">
            <LoadingOverlay show={advertisementsSpotsDataApiGet.status === API_STATUSES.PENDING}/>
            <AdvertisementsTable rowData={promotionsAdvertisements} variant={'MediaTable'}/>
        </PaperX>
    );
}

export default AdvertisementBrowserMediaSummary;