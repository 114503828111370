import {RowClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {handyAttributes, hardcodedPercent} from 'shared/handyAttributes';
import {
    PROMOTION_TYPES, promotionMediaTableRow,
    promotionMediaTableRowBE,
    promotionTableRow,
    promotionTableRowBE
} from 'shared/models/promotion.model';
import {shortCharacteristic} from 'shared/models/product.model';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {validity} from 'shared/models/validity.model';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import { useFormatMessage } from 'utils/translate';
import AttributesDisplay from 'components/Displays/AttributesDisplay';

type TableVariant = 'default' | 'MediaTable'

interface AdvertisementsTableProps {
    rowData: promotionTableRowBE[] | promotionMediaTableRowBE[],
    onRowClick?: (id: string) => void,
    variant?: TableVariant
}

const AdvertisementsTable = (props:AdvertisementsTableProps) => {
    const translate = useFormatMessage();

  const colDefs: ColDef[] = [
        { field: 'brand', headerName: translate({ id: 'b.brand' })},
        { field: 'description', headerName: translate({ id: 'b.descr' })},
        { field: 'category', headerName: translate({id: 'b.category' }), flex: 2},
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3,
            cellRenderer: (params) => <AttributesDisplay data={params.data.mainAttributes}/>},
        { field: 'variety', headerName: translate({id: 'b.variety' }), flex: 2},
        { field: 'contentOfTradingUnit', headerName: translate({id: 'b.contentOfTradingUnit' }), flex: 2},
        { field: 'capacity', headerName: translate({id: 'b.capacity' }), flex: 2},
        { field: 'promotionalPrice', headerName: translate({ id: 'b.promotionalPrice'})},
        { field: 'regularPrice', headerName: translate({ id: 'b.regularPrice'})},
        { field: 'relativeDiscount', headerName: translate({ id: 'b.relativeDiscount'}),
            cellRenderer: (params) => params.value ? `${params.value} ${hardcodedPercent}` : ''},
        { field: 'absoluteDiscount', headerName: translate({ id: 'b.absoluteDiscount'}),
            cellRenderer: (params) => params.value ? `${params.value} ${params.data.currency}`: ''},
        { field: 'generalInformation', headerName: translate({ id: 'b.general' }), flex: 4},
        { field: 'isTemplate', headerName: translate({ id: 'product.isTemplate' }),
            width: 160, cellClass: 'active-flag-cell',
            cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)
        },
        {...(props.variant !== 'MediaTable' && { field: 'frameValidity', headerName: translate({ id: 'frame.validity'}), flex: 2,
                valueGetter: (params) => {
                    const {frameValidFrom, frameValidTo} = params.data;
                    return frameValidFrom || frameValidTo ? `${frameValidFrom} - ${frameValidTo}` : '';
                }})},
        { field: 'validityDates', headerName: translate({ id: 'promotion.validity'}), flex: 2,
            valueGetter: (params) => params.data.validityDates.join(', '),
            tooltipField: 'validityDates'
        },
        { field: 'giveAwayBundling', headerName: translate({ id: 'productSearch.giveAwayBundling' }), flex: 2},
    ]
  ;

    const defaultColDef = {
        flex: 1,
        resizable: true,
        suppressMenu: true,
        sortable: true
    };

    const localStorageColumnsKey = props.variant === 'MediaTable' ? localStorageColumnsKeys.advertisementMedia : localStorageColumnsKeys.advertisementBrowser;

    return (
      <AgTable
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        localStorageColumnsKey={localStorageColumnsKey}
        enableBrowserTooltips={true}
        {...props}
        rowData={transformBETableData(props.rowData, props.variant)}
        onRowClicked={(rowData: RowClickedEvent) => {
            if (props.onRowClick) props.onRowClick(rowData.data.id);
        }}
      />
    );
}

const transformBETableData = (data: promotionTableRowBE[] | promotionMediaTableRowBE[], variant: TableVariant): promotionTableRow[] | promotionMediaTableRow[] => {

    return data.map((row) => {
        const isProduct: boolean = row.promotionObjectType === PROMOTION_TYPES.PRODUCT;

        let generalInformation = [];
        if (row.mpu) generalInformation.push(row.mpu);
        generalInformation = generalInformation.concat(row.promotionTypes).concat(row.themeSeasons).concat(row.countryThemedWeeks).concat(row.qualitySeals);

        let giveAwayBundlingValues = [];
        if (row.giveAwayBundledProducts && row.giveAwayBundledProducts.length > 0) {
            giveAwayBundlingValues.push(`${row?.giveAwayBundledProducts?.map(item => item.brand ? item.brand : item).join(', ')}`);
        }

        let description: string = '';
        if (isProduct) {
            description = row.product.description;
        } else {
            description = row.bpco.description;
        }

        const mainAttributes = isProduct ? row.product.characteristics : row.bpco.characteristics
        const filteredMainAttributes = mainAttributes.filter(attr => ![handyAttributes.variety, handyAttributes.contentOfTradingUnit, handyAttributes.capacity].includes(attr.id));
        const variety = mainAttributes.filter(attr => attr.id === handyAttributes.variety)[0]?.value ?? '';
        const contentOfTradingUnit = mainAttributes.filter(attr => attr.id === handyAttributes.contentOfTradingUnit)[0]?.value ?? '';
        const capacity = mainAttributes.filter(attr => attr.id === handyAttributes.capacity)[0]?.value ?? '';

        const flatRow: promotionTableRow | promotionMediaTableRow = {
            absoluteDiscount: row.absoluteDiscount,
            brand: isProduct ? row.product.brand : row.bpco.brand,
            category: isProduct ? row.product.category : row.bpco.category,
            currency: row.currency,
            description,
            generalInformation,
            id: row.id,
            isTemplate: isProduct ? row.product.isTemplate : false,
            mainAttributes: filteredMainAttributes,
            variety,
            contentOfTradingUnit,
            capacity,
            promotionalPrice: row.promotionalPrice,
            regularPrice: row.regularPrice,
            relativeDiscount: row.relativeDiscount,
            ...(variant !== 'MediaTable' && {
                frameValidFrom: row.frameValidFrom,
                frameValidTo: row.frameValidTo,
            }),
            giveAwayBundling: giveAwayBundlingValues,
            validityDates: row.validityDates.map((item) => makeValidityString(item))
        };
        return flatRow;
    });
};

export const extractMainAttributes = (characteristics: shortCharacteristic[]): string => characteristics
    .map((item) => item.value)
    .join(', ');

const makeValidityString = (validityDates: validity): string => {
    if (validityDates) {
        if (validityDates.validFrom === validityDates.validTo) {
            return validityDates.validFrom;
        } else {
            return `${validityDates.validFrom} - ${validityDates.validTo}`;
        }
    }
    return '';
}

export default AdvertisementsTable;